<template>
  <div class="crm">
    <div class="crm-name">{{ record ? record.title : "" }}</div>
    <div class="crm-time">{{ record ? formatTime(record.gmtCreate, "YYYY-MM-DD HH:mm:ss") : "" }}</div>
  </div>

  <!-- <div class="s-box f-flex">
    <div class="s-t">時间：</div>
    <div class="f-plr10">
      <select class="ant-select">
        <option value="1">1天內</option>
        <option value="7">7天內</option>
        <option value="15">15天內</option>
        <option value="30">30天內</option>
      </select>
    </div>
    <button class="btn-blue">查询</button>
  </div> -->

  <div class="table-con w750" style="font-size:18px;">
    <div v-html="record ? record.contents : ''"></div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "LhcLog",
  setup() {
    const store = useStore();
    const route = useRoute();
    var loading = ref(false);
    let record = ref(null);

    onMounted(() => {
      requestNoticeDetail();
    });

    //獲取公告明細
    const requestNoticeDetail = () => {
      loading.value = true;
      store
        .dispatch("game/requestNoticeDetailAction", { id: route.query.id })
        .then((res) => {
          loading.value = false;
          if (res.code == 0) {
            record.value = res.res;
          }
        })
        .catch(() => {
          loading.value = false;
        });
    };

    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(time)
        .format(format);
    };

    return {
      loading,
      record,
      formatTime,
      requestNoticeDetail,
    };
  },
};
</script>